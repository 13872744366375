<template>
  <div class="warp">
    <div class="header">
      <span>请选择您的学习方向</span>
      <van-icon @click="close" class="close" name="cross"/>
    </div>

    <div class="dirWarp">
      <template v-for="obj in list">
        <div class="dirTitle">{{ obj.name }}</div>
        <template v-for="(item,index) in obj.children">
          <span @click=selectCoach(item) class="dirList" :class="item.id === activeId?'active':''"
                :key="item.id">{{ item.name }}</span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '职称考试',
          id: '12',
          children: [
            {
              name: '初级',
              id: 6778
            },
            {
              name: '中级',
              id: '122'
            },
            {
              name: '注册会计师',
              id: '132'
            },
            {
              name: 'CMA',
              id: '1323'
            }
          ]
        },
        {
          name: '基础实务',
          id: '1df2',
          children: [
            {
              name: '零基础入门',
              id: '13232'
            },
            {
              name: '常规做账',
              id: '1328'
            },
            {
              name: '税务',
              id: '13287'
            },
          ]
        },
        {
          name: '基础实务',
          id: '1df2',
          children: [
            {
              name: 'CMA',
              id: '132098'
            },
            {
              name: '会计师助理',
              id: '132565'
            },
            {
              name: '财务会计',
              id: '1329'
            },
          ]
        },
      ],
      activeBtn: false,
      activeId: '',

    }
  },
  name: 'directionFirst',
  methods: {
    selectCoach(item) {
      this.activeId = item.id;
      console.log(item);
      this.$forceUpdate();
    },
    close() {
      this.$router.go(-1);
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  margin-top: 17px;
  position: relative;
}

.header span {
  height: 27px;
  font-size: 19px;
  display: inline-block;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 27px;
}

.close {
  font-size: 17px;
  position: absolute;
  right: 14px;
  top: 5px;
  color: #666666;
}

.dirWarp {
  padding: 0 14px;
  height: calc(100vh - 46px);
}

.dirTitle {
  margin: 27px 0 18px;
  /*margin-top: 27px;*/
  height: 22px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 22px;
}

.dirList {
  text-align: center;
  display: inline-block;
  width: 101px;
  height: 29px;
  background: #F8F8F8;
  border-radius: 15px;
  margin-bottom: 14px;
  margin-right: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 29px;
  border: 1px solid transparent;
}

.active {
  color: #5D7DFF;
  background: #E7ECFF;
  border: 1px solid #5D7DFF;
}


.dirWarp .dirList:nth-child(3n+3) {
  margin-right: 0;
}
</style>
